import React, { useState } from "react";
// import ReactFormInputValidation from "react-form-input-validation";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import SignInForm from './index';
import { graphql } from "gatsby"
// import LOGIN from "../../mutations/login"
// import { useQuery, useMutation } from "@apollo/client";
import { setAuth } from "../../utils/functions";
import axios from "axios"
const Form = ({ shouldRefresh }) => {
  // const { t } = useTranslation();
  // if (typeof window !== 'undefined') {
  //   ReactFormInputValidation.setAttributeFormatter(function (attribute) {
  //     return t(attribute)
  //   })
  //   ReactFormInputValidation.register(
  //     "required",
  //     function (value, attribute) {
  //       return value !== ""
  //     },
  //     `${t('The')} :attribute ${t('fieldIsRequired')}`
  //   )

  //   ReactFormInputValidation.register(
  //     "email",
  //     function (value, attribute) {
  //       const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //       return reg.test(value);
  //     },
  //     `${t('The')} :attribute ${t('formatIsInvalid')}`
  //   )

  // }

  const [errorMsg, setErrorMsg] = useState(null);

  // const login = async (form, email) => {
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: `${process.env.WORDPRESS_API_URL}/wp-json/api/login`,
  //       data: form,
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //     });
  //     if (response.data.success && typeof response.data === "object") {
  //       const accountCheck = await axios({
  //         method: "get",
  //         url: `${process.env.WORDPRESS_API_URL}/special-check/${email}`
  //       });
  //       const authData = {
  //         authToken: response.data.authToken,
  //         authExpiration: response.data.jwtAuthExpiration,
  //         userName: response.data.username,
  //         userID: response.data.id,
  //         isSpecial: accountCheck.data,
  //         //user: login.user,
  //       };
  //       await setAuth(authData);
  //       if (!reminder) {
  //         localStorage.setItem("reminder", "1")
  //       }
  //       const reminder = localStorage.getItem("reminder")
  //       if (typeof shouldRefresh == 'function') {
  //         shouldRefresh()
  //         return
  //       }
  //       var url = window.location.search.split("=")[1];
  //       if (url) {
  //         window.location.href = url;
  //       }
  //       else {
  //         window.location.href = "/"
  //       }
  //     } else {
  //       setErrorMsg(response.data.message);
  //     }
  //   } catch (e) {
  //     if (e.data) {
  //       setErrorMsg(e.data);
  //     } else if (e.message) {
  //       setErrorMsg(e.message);
  //     }
  //   }
  // }



  const handleGetLoginMsg = async (data) => {
    var formData = new FormData(); // Currently empty
    formData.append('username', data.email_address);
    formData.append('password', data.password);
    // await login(formData);
    axios({
      method: "post",
      url: `${process.env.WORDPRESS_API_URL}/wp-json/api/login`,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then(async function (response) {
        if (typeof response.data === "object") {
          const accountCheck = await axios({
            method: "get",
            url: `${process.env.WORDPRESS_API_URL}/special-check/${data.email_address}`
          });
          const authData = {
            authToken: response.data.authToken,
            authExpiration: response.data.jwtAuthExpiration,
            userName: response.data.username,
            userID: response.data.id,
            isSpecial: accountCheck.data.isSpecial,
            //user: login.user,
          };

          // console.info(accountCheck.data);

          setAuth(authData).then(() => {
            try {
              const reminder = localStorage.getItem("reminder")
              if (!reminder) {
                localStorage.setItem("reminder", "1")
              }
              if (typeof shouldRefresh == 'function') {
                const task =new Promise(() => {
                  shouldRefresh()
                });
                task();
                return
              }
              var url = window.location.search.split("=")[1];
              if (url) {
                window.location.href = url;
              }
              else {
                window.location.href = "/"
              }
            } catch (e) {
              console.error(e)
            }

          })
        }
        else {
          setErrorMsg(response.data)
        }

      })
      .catch(function (response) {
        console.error(response)
        setErrorMsg(response.data)
        // console.log(response.data)
      });
  }
  return (
    <SignInForm getLoginMsg={handleGetLoginMsg} errorMsg={errorMsg} />
  )

}
export default Form
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
