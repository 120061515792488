import React, { useState } from "react";
import HeardTopWrapper from "../../components/header-wrapper"
import Layout from "../../components/layout/index"
import PageContainer from "../../components/page-container"
import { Link, graphql } from "gatsby"
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import BlogDefaultImage from "../../images/blog-defealt.png"
import CartIcon from "../../components/cart/cart-icon-right"
import { Helmet } from 'react-helmet';
import ADD_TO_CART from "../../mutations/add-to-cart"
import { useQuery, useMutation } from "@apollo/client";
import { isSpecialUserLoggedIn, setStartUrl, toLogin } from "../../utils/functions"
import "./style.scss"
import GET_CART from '../../queries/get-cart'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
// import loadable from '@loadable/component'
import SignInForm from '../../components/signinform/form';
import SignUp from '../../components/freeTrial/create-form';
// const SignInForm = React.lazy(() => {
//   import('../../components/signinform/form')
// })
// const SignInForm = loadable(() => import('../../components/signinform/form'))
// SignInForm.preload()
let isGotoCart = false;
const ShopDetail = ({ data }) => {
  const { t } = useTranslation();
  const notify = () => toast.success(t('itemAdded'));
  // const isSSR = typeof window === "undefined"
  // console.info('product detail - ', data.wpSimpleProduct)
  const [cartNum, setCartNum] = useState(1);
  const [isLoading, setLoading] = useState(false)
  const [productFields, setProductFields] = useState({
    productId: "",
    quantity: ""
  });
  const [isSigning, setSigning] = useState(true);
  const [loginModal, setLoginModal] = useState(false);
  const [totalQty, setTotalQty] = useState(null);
  const { name, price, description, shortDescription, databaseId, stockStatus, productCategories, attributes } = data.wpSimpleProduct
  const { metaDesc, metaKeywords, title } = data.wpSimpleProduct.seo
  const getCartResult = useQuery(GET_CART, {
    fetchPolicy: "network-only",   // Used for first execution
    nextFetchPolicy: "cache-first",// Used for subsequent executions
    onCompleted: () => {
      setTotalQty(getCartResult.data.cart.contents.itemCount)
    },
    onError: (error) => {
      toast.error(error)
    },
  });
  const isSpecial = productCategories && productCategories.nodes.some((category) => category.name === "Special");
  if (isSpecial) {
    setStartUrl()
  }
  const sharecodeAttribute = attributes && attributes.nodes.find((attribute) => attribute.name === "share_code");
  const sharecode = sharecodeAttribute && sharecodeAttribute.options && sharecodeAttribute.options.length> 0? sharecodeAttribute.options[0] : null;
  // console.info(sharecode);
  const changeCartNum = (e) => {
    const numvalue = e.target.value;
    if (numvalue <= 0) return;
    setCartNum(numvalue)
  }
  const addToCartNum = () => {
    if (isSpecialUserLoggedIn()) {
      setLoading(true)
      setProductFields({
        productId: databaseId,
        quantity: Number(cartNum),
      })
      setTimeout(() => {
        addToCartFun()
      }, 1000)
    }
    else {
      if (isSpecial) {
        setLoginModal(true)
      } else {
        toLogin()
      }
    }
  }

  const onSignup=()=>{
    setLoginModal(false);
    setSigning(true);
    window.location.reload();
  }

  const onSignupCancel=()=>{
    setSigning(false);
    setLoginModal(false);
  }

  const cartHandler = () => {
    isGotoCart = true
    if (isSpecial) {
      if (isSpecialUserLoggedIn()) {
        window.location.href = "../../special"
      }
      else {
        setLoginModal(true)
      }
    } else {
      if (isSpecialUserLoggedIn()) {
        window.location.href = "../../cart"
      }
      else {
        toLogin()
      }
    }

  }

  const afterSignin = () => {
    setLoginModal(false)
    if (isGotoCart) {
      window.location.href = "../../special"
    } else {
      setLoading(true)
      setProductFields({
        productId: databaseId,
        quantity: Number(cartNum),
      })
      setTimeout(() => {
        addToCartFun()
      }, 1000)
    }
    isGotoCart = false
  }
  const handleClose = () => {
    setLoginModal(false)
    setSigning(true);
  }
  const [
    addToCartFun,
    { data: addToCartRes, loading: addToCartLoading, error: addToCartError },
  ] = useMutation(ADD_TO_CART, {
    variables: {
      input: productFields,
    },
    onCompleted: (data) => {
      notify()
      setTotalQty(data.addToCart.cart.contents.itemCount)
      setLoading(false)

    },
    onError: (error) => {
      toast.error(error.message)
    },
  });
  return (
    <>
      <HeardTopWrapper title={name} isSpecial={isSpecial} />
      <Layout>
        <Helmet>
          <title>{t('myKitchenInspector')}｜{name}</title>
          <meta
            name='description'
            content={metaDesc}
          />
          <meta
            name="keywords"
            content={metaKeywords} />
        </Helmet>
        <PageContainer>
          <>
            <Container fluid>
              <div className="topBreadscrum">
                <div className="topBreadscrumLeft">
                  {
                    !isSpecial && (<><Link to="/shop" className="top-nav">{t('productList')}</Link> &gt;</>)
                  }
                  {name}
                </div>
                <CartIcon totalQty={totalQty} handleToCart={cartHandler} />
              </div>
              <Row>
                <Col xs={12} md={6}>
                  <div className="productImgWrap">
                    <div>
                      <Image src={`${data.wpSimpleProduct.image ? data.wpSimpleProduct.image.sourceUrl : BlogDefaultImage}`} rounded />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <h4>{name}</h4>
                  <h1 className="price">{price}</h1>
                  <div dangerouslySetInnerHTML={{ __html: shortDescription }}></div>
                  <div className="addNumber">
                    <input type="number" className="form-control" value={cartNum} onChange={changeCartNum.bind(this)} />

                    {/* <input type="number" className="form-control" value={cartNum}  onChange={(e) => setCartNum(e.target.value)}/> */}
                    <Button className="addButton" disabled={stockStatus === "OUT_OF_STOCK"} onClick={addToCartNum.bind(this, data.wpSimpleProduct)}>{isLoading ? 'Adding…' : 'Add to cart'}</Button>
                    <span className={stockStatus === "OUT_OF_STOCK" ? "error" : ""}>{stockStatus === "OUT_OF_STOCK" ? "Out of stock" : ""}</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h5>{t('description')}</h5>
                  <div dangerouslySetInnerHTML={{ __html: description }}></div>
                </Col>
              </Row>
            </Container>
            <div className="modal-backdrop" style={{ display: loginModal ? "block" : "none" }}>
              <div className="modal" style={{ display: loginModal ? "block" : "none", height: '60vh' }}>
                {isSigning?<>
                  <SignInForm shouldRefresh={afterSignin} />
                  <div className="or-line">
                    <span>{t('OR')}</span>
                  </div>
                  <p className="text-center">{t('pleaseClick')} <span className="signup" onClick={()=>setSigning(false)}>{t('here')}</span> {t('EnrollAccount')}</p>
                </>:
                <SignUp sharecode={sharecode} onSuccess={onSignup} onCancel={onSignupCancel}></SignUp>}

                <i onClick={handleClose} className="modal-close-btn iconfont icon-close"></i>
              </div>
            </div>





            {/* <div dangerouslySetInnerHTML={{ __html: data.wpProduct.content }}></div> */}
          </>
        </PageContainer>
      </Layout>
      <ToastContainer />
    </>

  )
}
export const query = graphql`
    query($slug: String!,$language: String!) {
      locales: allLocale(filter: {language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
      wpSimpleProduct(slug: {eq: $slug}) {
        id
        databaseId
        name
        price
        slug
        description
        stockStatus
        image {
          sourceUrl
        }
        productCategories {
          nodes {
            name
          }
        }
        attributes {
          nodes{ 
            name
            options
          }
       }
        shortDescription
        seo {
          canonical
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
        }
      }
    }
`

export default ShopDetail
