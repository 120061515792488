import React, { useState } from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { BaseUrl } from "../../consts";
import { setAuth } from "../../utils/functions";
import axios from "axios"
// import { graphql } from "gatsby"

// const isSSR = typeof window === "undefined"

const CreateForm = ({ onSuccess, sharecode, onCancel }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [company, setCompany] = useState('');
  const [lastname, setLastname] = useState('');
  const [language, setLanguage] = useState('en');
  const [formError, setFormError] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const emailHandler = (e) => {
    setEmail(e.target.value);
  }

  const validate = (field, value) => {
    let isValid = true;
    switch (field) {
      case "company":
        if (value === "") {
          setFormError({ ...formError, company: `${t('The')} ${t('Organization')} ${t('fieldIsRequired')}` });
          isValid = false;
        } else {
          delete formError.company
          setFormError({ ...formError });
        }
        break;
      case "firstname":
        if (value === "") {
          setFormError({ ...formError, firstname: `${t('The')} ${t('First Name')} ${t('fieldIsRequired')}` });
          isValid = false;
        } else {
          delete formError.firstname
          setFormError({ ...formError });
        }
        break;
      case "lastname":
        if (value === "") {
          setFormError({ ...formError, lastname: `${t('The')} ${t('Last Name')} ${t('fieldIsRequired')}` });
          isValid = false;
        } else {
          delete formError.lastname
          setFormError({ ...formError });
        }
        break;
      case "email":
        if (value === "") {
          setFormError({ ...formError, email: `${t('The')} ${t('EmailAddress')} ${t('fieldIsRequired')}` });
          isValid = false;
          // return "Email is required";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          setFormError({ ...formError, email: `${t('The')} ${t('EmailAddress')} ${t('formatIsInvalid')}` });
          isValid = false;
        } else {
          delete formError.email
          setFormError({ ...formError });
        }
        break;
    }
    return isValid;
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const status = validate('email', email)
      && validate('firstname', firstname)
      && validate('lastname', lastname)
      && validate('company', company);
    const errors = Object.keys(formError)

    if (errors.length > 0 || !status) {
      return
    }
    const fields = {
      name: company,
      contactFirstName: firstname,
      contactLastName: lastname,
      emailAddress: email,
    };
    console.log(fields);
    try {


      const response = await axios({
        method: "post",
        url: `${BaseUrl}/share-code/register?shareCode=${sharecode}`,
        data: fields,
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      });
      console.log(response.data);
      if (typeof response.data === "object") {
        if (typeof response.data.ok == 'boolean') {
          setErrorMsg(response.data.message);
        }
        if (response.data.isValid) {
          const accountCheck = await axios({
            method: "get",
            url: `${BaseUrl}/special-check/${email}`,
            headers: {
              "Content-Type": "application/json; charset=utf-8"
            }
          });
          const authData = {
            authToken: response.data.authToken,
            authExpiration: response.data.jwtAuthExpiration,
            userName: response.data.username,
            userID: response.data.id,
            isSpecial: accountCheck.data.isSpecial,
            //user: login.user,
          };
          await setAuth(authData);
          onSuccess();
        }

      } else {
        setErrorMsg(response.data);
      }
    }
    catch (error) {
      setErrorMsg(error.message);
    }

  }

  return (
    <form className="formWrap signInModal">
      {errorMsg && <label className="error">{errorMsg}</label>}
      <p>
        <label>
          <Trans>Organization</Trans> <span>*</span>
          <input
            className="form-control"
            type="text"
            name="company"
            onBlur={() => validate("company", company)}
            onChange={(e) => setCompany(e.target.value)}
            value={company}
          />
        </label>
        <label className="error">
          {formError.firstname ? formError.firstname : ""}
        </label>
      </p>
      <p className="col2">
        <div className="cell">
          <label>
            <Trans>First Name</Trans> <span>*</span>
            <input
              className="form-control"
              type="text"
              name="firstname"
              onBlur={() => validate("firstname", firstname)}
              onChange={(e) => setFirstname(e.target.value)}
              value={firstname}
            />
          </label>
          <label className="error">
            {formError.firstname ? formError.firstname : ""}
          </label>
        </div>
        <div className="cell">
          <label>
            <Trans>Last Name</Trans> <span>*</span>
            <input
              className="form-control"
              type="text"
              name="lastname"
              onBlur={() => validate("lastname", lastname)}
              onChange={(e) => setLastname(e.target.value)}
              value={lastname}
            />
          </label>
          <label className="error">
            {formError.lastname ? formError.lastname : ""}
          </label>
        </div>
      </p>
      <p>
        <label>
          {t('EmailAddress')} <span>*</span>
          <input
            className="form-control"
            type="text"
            name="email_address"
            onBlur={() => validate("email", email)}
            onChange={emailHandler}
            value={email}
          />
        </label>
        <label className="error">
          {formError.email ? formError.email : ""}
        </label>
      </p>

      <p>
        <label>
          <Trans>Language</Trans><span>*</span>
          <select
            id="selectLang"
            className="form-control"
            type="text"
            name="language"
            onBlur={() => validate("language", language)}
            onChange={(e) => setLanguage(e.target.value)}
            value={language}
          >
            <option value="en">{t('English')}</option>
            <option value="es">{t('Spanish')}</option>
            <option value="it">{t('Italian')}</option>
          </select>
        </label>
        <label className="error">
        </label>
      </p>
      <p>
        <button type="button" className="submitBtn" onClick={onSubmit} >{t('Signup')}</button>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <button type="button" className="submitBtn" style={{ background: "#ccc" }} onClick={onCancel}>{t('Cancel')}</button>
      </p>
    </form>
  );
}


export default CreateForm;
