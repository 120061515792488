import React, { useState } from "react";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
// import { graphql } from "gatsby"

// const isSSR = typeof window === "undefined"

const SignInForm = ({getLoginMsg, errorMsg}) => {
  const {t} = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remeber, setRemeber] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [formError, setFormError] = useState({});
  const emailHandler = (e) => {
    setEmail(e.target.value);
  }
  const passwordHandler = (e) => {
    setPassword(e.target.value);
  }
  const rememberHandler = (e) => {
    setRemeber(e.target.checked);
  }

  const validate = (field, value) => {
    let isValid = true;
    if (field === "email") {
      if (value === "") {
        setFormError({ ...formError, email: `${t('The')} Email ${t('fieldIsRequired')}`});
        isValid = false;
        // return "Email is required";
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        setFormError({ ...formError, email: `${t('The')} Email ${t('formatIsInvalid')}` });
        isValid = false;
      } else {
        delete formError.email
        setFormError({ ...formError });
      }
    } else if (field === "password") {
      if (value === "") {
        setFormError({ ...formError, password: `${t('The')} Password ${t('fieldIsRequired')}` });
        isValid = false;
      } else {
        delete formError.password
        setFormError({ ...formError });
      }
    }
    return isValid;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const fields = {
      email_address: email,
      password: password,
      remember: remeber
    };
    const status = validate('email', email) && validate('password', password);
    const errors = Object.keys(formError)
    
    if(errors.length > 0 || !status){
      return
    }
    getLoginMsg(fields)
  }

  return (
    <form className="formWrap signInModal">
      {errorMsg&& <label className="error">{errorMsg}</label>}
      <p>
        <label>
          {t('EmailAddress')} <span>*</span>
          <input
            className="form-control"
            type="text"
            name="email_address"
            onBlur={()=>validate("email", email)}
            onChange={emailHandler}
            value={email}
          />
        </label>
        <label className="error">
          {formError.email ? formError.email : ""}
        </label>
      </p>
      <p>
        <label>
          {t('Password')}<span>*</span>
          <span className="input-group">
            <input
              className="form-control"
              type={showPassword ? 'text' : 'password'}
              name="password"
              onBlur={()=>validate("password", password)}
              onChange={passwordHandler}
              value={password}
            />
            <span className="input-group-addon" onClick={() => setShowPassword(!showPassword)}><i className={showPassword ? "iconfont icon-eye" : "iconfont icon-eye-close"}></i></span>
          </span>

        </label>
        <label className="error">
          {formError.password ? formError.password : ""}
        </label>
      </p>
      <p>
        <label htmlFor="rememberme"><input type="checkbox" id="rememberme" name="rememberme"
          onChange={rememberHandler}
          value={remeber} /> {t('rememberMe')}</label>
      </p>
      <p>
        <button type="button" className="submitBtn" onClick={onSubmit} >{t('SignIn')}</button>
      </p>
    </form>
  );
}


export default SignInForm;
// export const query = graphql`
//   query ($language: String!) {
//     locales: allLocale(filter: {language: {eq: $language}}) {
//       edges {
//         node {
//           ns
//           data
//           language
//         }
//       }
//     }
//   }
// `;
